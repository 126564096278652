import React from 'react'
import html from "../assets/html.png"
import css from "../assets/css.png"
import javascript from "../assets/javascript.png"
import reactImage from "../assets/react.png"
import github from "../assets/github.png"
import tailwind from "../assets/tailwind.png"
import python from "../assets/python.png"
import tensor from "../assets/tensorflow.png"
import kerasImage from "../assets/keras.png"
import pandas from "../assets/pandas.png"
import cplusplus from "../assets/cplusplus.png"
import android from "../assets/android.png"
import aws from "../assets/aws.png"
import mysql from "../assets/mysql.png"

const Experience = () => {
  
  const techs = [
    {
        id: 1,
        src: python,
        title: 'Python',
        style: 'shadow-sky-600',
    },
    {
        id: 2,
        src: tensor,
        title: 'TensorFlow',
        style: 'shadow-yellow-300',
    },
    {
        id: 3,
        src: kerasImage,
        title: 'Keras',
        style: 'shadow-red-500',
    },
    {
        id: 4,
        src: pandas,
        title: 'Pandas',
        style: 'shadow-blue-800',
    },
    {
        id: 5,
        src: cplusplus,
        title: 'C++',
        style: 'shadow-indigo-300',
    },
    {
        id: 6,
        src: html,
        title: 'HTML',
        style: 'shadow-orange-500',
    },
    {
        id: 7,
        src: css,
        title: 'CSS',
        style: 'shadow-blue-500',
    },
    {
        id: 8,
        src: javascript,
        title: 'JavaScript',
        style: 'shadow-yellow-500',
    },
    {
        id: 9,
        src: reactImage,
        title: 'React',
        style: 'shadow-blue-600',
    },
    {
        id: 10,
        src: tailwind,
        title: 'Tailwind',
        style: 'shadow-sky-400',
    },
    {
        id: 11,
        src: android,
        title: 'Android',
        style: 'shadow-green-500',
    },
    {
        id: 12,
        src: aws,
        title: 'AWS',
        style: 'shadow-orange-400',
    },
    {
        id: 13,
        src: github,
        title: 'GitHub',
        style: 'shadow-gray-400',
    },
    {
        id: 14,
        src: mysql,
        title: 'MySQL',
        style: 'shadow-emerald-600',
    },
  ]
  
    return (



    <div name='experience' className="bg-gradient-to-b from-gray-800 to-black w-full sm:h-full">
       <div className="max-w-screen-lg mx-auto p-4 flex flex-col justify-center w-full h-full text-white">
        <div>
            <p className="text-4xl font-bold border-b-4 border-gray-500 p-2 inline">Experience</p>
            <p className="py-6">These are the technologies I've worked with</p>
        </div>

        <div className="w-full grid grid-cols-2 sm:grid-cols-3 gap-8 text-center py-8 px-12 sm:px-0">

            {
                techs.map(({id, src, title, style}) => (
                    <div key={id} className={`shadow-md hover:scale-105 duration-500 py-2 rounded-lg ${style}`}>
                    <img src={src} alt="" className="w-20 mx-auto"/>
                    <p className="mt-4">{title}</p>
                </div>
                ))}
            

           
        </div>
       </div>
    </div>
  )
}

export default Experience